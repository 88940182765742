exports.components = {
  "component---src-components-posts-page-layout-js": () => import("./../../../src/components/posts-page-layout.js" /* webpackChunkName: "component---src-components-posts-page-layout-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-fundamentals-js": () => import("./../../../src/pages/fundamentals.js" /* webpackChunkName: "component---src-pages-fundamentals-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-practices-js": () => import("./../../../src/pages/practices.js" /* webpackChunkName: "component---src-pages-practices-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */)
}

